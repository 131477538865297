import { useState } from 'react'

import Accordian from 'react-bootstrap/Accordion'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'

import { yAxis } from './data'
import Button from 'react-bootstrap/esm/Button'

export function YAxisOptions({
    optionsData,
    handleChange,
    eventKey = 2,
}) {
    // STATE
    const [showYAxisObj, yAxisDomainObj, yAxisUnitsObj] = yAxis.options

    const [customTickValues, setCustomTickValues] = useState('')
    const [units, setUnits] = useState(yAxisUnitsObj.value)

    const [isInvalid, setIsInvalid] = useState(false)
    const [disableSaveButton, setDisableSaveButton] = useState(true)
    const [disableUnitsSaveButton, setDisableUnitsSaveButton] = useState(true)


    // STATE CHANGE
    // - general
    const handleOnFocus = e => e.target.style.background = 'yellow'
    const handleBlur = e => e.target.style.background = 'white'

    // - tick values
    const strToArr = str => str.split(',').map(e => Number(e))
    const handleCustomTickValues = valuesStr => {

        let disable = true

        const first = valuesStr[0]
        const last = valuesStr[valuesStr.length - 1]

        if (
            (!isNaN(first) || first === '-' || first === ' ')
            && valuesStr.includes(',')
            && !isNaN(last)
            && last !== ' '     // space evaluates as number; edge case of '0, ' passing above criteria
        ) {
            const valuesArr = strToArr(valuesStr)

            disable = valuesArr.every(e => !isNaN(e))
                ? false
                : true
        }

        setDisableSaveButton(disable)
        setIsInvalid(disable)

        setCustomTickValues(valuesStr)
    }

    const handleSave = () => {

        handleChange({
            type: 'radio',
            groupKey: 'ticksCustomValues',
            key: strToArr(customTickValues)
        })

        setDisableSaveButton(true)
    }

    // - units
    const handleUnitValues = e => {

        setUnits(e)
        setDisableUnitsSaveButton(false)
    }

    const handleUnitsSave = e => {

        handleChange({
            type: 'radio',
            groupKey: 'yAxisUnits',
            key: units
        })

        setDisableUnitsSaveButton(true)
    }


    return (
        <Accordian.Item eventKey={eventKey}>
            <Accordian.Header>{yAxis.label}</Accordian.Header>
            <Accordian.Body>

                <InputGroup className='mb-3'>
                    <Form.Check
                        type={showYAxisObj.type}
                        label={showYAxisObj.title}
                        checked={optionsData.showYAxis ? true : false}
                        onChange={() => handleChange(showYAxisObj)}
                    />
                </InputGroup>

                <hr />

                <div className='mb-3'>
                    {yAxisDomainObj.options.map(option => (

                        <div key={option.key}>

                            <Form.Check
                                type={option.type}
                                label={option.title}
                                disabled={optionsData.showYAxis ? false : true}
                                checked={optionsData[option.groupKey] === option.key ? true : false}
                                onChange={() => handleChange(option)}
                            />

                            {option.key === 'ticksCustom' &&

                                <InputGroup>
                                    <Form.Control
                                        disabled={optionsData[option.groupKey] === option.key ? false : true}
                                        placeholder='0, 20, 40, 50'
                                        type='text'
                                        name='ticksCustom'
                                        value={customTickValues}
                                        onFocus={handleOnFocus}
                                        onChange={e => handleCustomTickValues(e.target.value)}
                                        onBlur={handleBlur}
                                        isInvalid={isInvalid}
                                    />
                                    <Button
                                        disabled={disableSaveButton}
                                        variant={!disableSaveButton ? 'primary' : 'outline-primary'}
                                        type='button'
                                        onClick={handleSave}
                                    >
                                        Save
                                    </Button>
                                </InputGroup>
                            }

                        </div>
                    ))}
                </div>

                <hr />

                <InputGroup>
                    <InputGroup.Text>{yAxisUnitsObj.title}</InputGroup.Text>
                    <Form.Control
                        disabled={optionsData.showYAxis ? false : true}
                        placeholder={yAxisUnitsObj.value}
                        type='text'
                        name='yAxisUnits'
                        maxLength='10'
                        value={units}
                        onFocus={handleOnFocus}
                        onChange={e => handleUnitValues(e.target.value)}
                        onBlur={handleBlur}
                    />
                    <Button
                        disabled={disableUnitsSaveButton}
                        variant={!disableUnitsSaveButton ? 'primary' : 'outline-primary'}
                        type='button'
                        onClick={handleUnitsSave}
                    >
                        Save
                    </Button>
                </InputGroup>

            </Accordian.Body>
        </Accordian.Item>
    )
}