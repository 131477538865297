/* adapted from: ~/vizball/react_client/src/v2_Features/PageTemplate/components/ChartParent.v2.jsx

    - attach resize monitor to get chartElementWidth
        -   pass down to Chart where dimensions calculated as needed
        -   also means no longer need to pass down maxWidth prop from top parent all the way down to here
        -   NOTE: 
                cannot simplify further by attaching resize monitor at Chart level else Save button will not work properly

    - below structure <Row /><Col /> for container, SVG etc is from ChartParent.v2
        and allows for multiple charts
        although SaveButton has been modified from VizBall
        and no longer supports multiple charts
*/

import { nanoid } from "nanoid";

import Row from "react-bootstrap/Row"

import { useResizeMonitor } from "../d3/utils";

import { SaveButton } from "./SaveButton";

export function ChartParent({
    filename = "BZ-Viz",           // filename for saved chart
    // for Chart
    Chart,                          // chart component
    chartOptions = {},              // chart options grouped by type (see below)
}) {
    /* chartOptions is obj holding values and attributes for creating chart, eg

        {
            data: [ { 'position': int, 'label': str, 'value': int }, ],
            colColors,
            chartOrientation,
            labelPosition,
        }

        NOTE: 'data' property always present
    */

    // ========================================
    //      data
    // ========================================


    // ========================================
    //      component
    // ========================================
    // for save button
    const imgContainerID = nanoid()

    // for chart
    // - cannot (yet) factor out else save button won't work (it searches for <div>, so need minimize)
    const [chartElementWidth, containerRef] = useResizeMonitor()

    return (
        <>

            <Row className="m-0 mb-3 p-0" ref={containerRef} id={imgContainerID} >
                {
                    chartOptions.data.length > 0 &&

                    <Chart
                        chartOptions={chartOptions}
                        chartElementWidth={chartElementWidth}
                    />
                }
            </Row>


            <SaveButton
                filename={filename}
                imgContainerID={imgContainerID}
            />
        </>
    )
}