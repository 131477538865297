import { useState } from 'react'

import Accordian from 'react-bootstrap/Accordion'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import * as d3Format from 'd3-format'
import * as d3Array from 'd3-array'

import { AddEditRow } from './AddEditRow'
import { DisplayRow } from './DisplayRow'
import { YAxisOptions } from './YAxisOptions'
import { colColors, chartOptions } from './data'

export function ChartOptionsCardV2({
    optionsData,
    setOptionsData,
}) {
    const [draggingItem, setDraggingItem] = useState(null)

    // re chart options, data
    const dataPrecision = dataArr => {

        const maxPrecision = d3Array.max(
            dataArr.map(
                d => Number.isInteger(d)
                    ? 0
                    : d.toString().split('.')[1].length
            ))

        return maxPrecision !== optionsData.precision
            ? {
                'precision': maxPrecision,
                'format': v => d3Format.format(`.${maxPrecision}f`)(v)
            }
            : {}
    }

    const handleDataAdd = newDatum => {

        const x = dataPrecision([...optionsData.data.map(e => e.value), newDatum.value])

        setOptionsData({
            ...optionsData,
            'data': [
                ...optionsData.data,
                {
                    ...newDatum,
                    'position': optionsData.data.length
                }
            ],
            ...x
        })
    }

    const handleDataEdit = editedDatum => {

        const i = optionsData.data.findIndex(e => e.position === editedDatum.position)
        const t = [...optionsData.data]
        t[i] = { ...editedDatum, 'edit': false }

        const x = dataPrecision(t.map(e => e.value))

        setOptionsData({
            ...optionsData,
            "data": t,
            ...x
        })
    }

    const handleChangeEditStatus = position => {

        const i = optionsData.data.findIndex(e => e.position === position)
        const t = [...optionsData.data]

        t[i] = { ...optionsData.data[i], 'edit': !optionsData.data[i].edit }

        setOptionsData({
            ...optionsData,
            "data": t,
        })
    }

    const renumberPosition = arr => arr.map((e, i) => { e.position = i; return e })
    const handleDataDelete = position => {

        // delete row
        const i = optionsData.data.findIndex(e => e.position === position)
        const t = [...optionsData.data]
        t.splice(i, 1)

        // renumber
        const t1 = renumberPosition(t)

        setOptionsData({
            ...optionsData,
            'data': t1
        })
    }


    // re chart options, data drag & drop to re-order
    const handleDragStart = (event, item) => {

        setDraggingItem(item)

        // necessary for firefox
        event.dataTransfer.setData('text/plain', '')
    }

    const handleDragEnd = () => {

        setDraggingItem(null)
    }

    const handleDragOver = e => e.preventDefault()

    const handleDrop = (e, target) => {

        if (!draggingItem) return

        const currIndex = optionsData.data.findIndex(e => e.position === draggingItem.position)
        const targetIndex = optionsData.data.findIndex(e => e.position === target.position)

        if (currIndex !== -1 && targetIndex !== -1) {

            const temp = [...optionsData.data]

            temp.splice(currIndex, 1)                   // remove dragging item from list
            temp.splice(targetIndex, 0, draggingItem)   // insert dragging into new location

            const temp1 = renumberPosition(temp)        // renumber 'position' property

            setOptionsData({
                ...optionsData,
                'data': temp1,
            })
        }
    }


    // re chart options, column colors
    const handleColorChange = (id, value) => {

        setOptionsData({ ...optionsData, [colColors.key]: { ...optionsData[colColors.key], [id]: value } })
    }


    // re chart options, general
    const handleChange = (option) => {

        if (option.type === 'radio') {

            setOptionsData({ ...optionsData, [option.groupKey]: option.key })

        } else {

            const t = { ...optionsData }
            optionsData[option.key]
                ? delete t[option.key]
                : t[option.key] = true

            setOptionsData(t)
        }
    }

    return (
        <Accordian defaultActiveKey={0} className='mt-3 mb-3' alwaysOpen>

            {/* chart options: data */}
            <Accordian.Item eventKey='0' >
                <Table>
                    <thead>
                        <tr className='text-center'>
                            <th></th>
                            <th>Name</th>
                            <th>Value</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>

                        {optionsData.data.map(e => e.edit
                            ? <AddEditRow
                                key={e.position}
                                handleDataChange={handleDataEdit}
                                initValues={e}
                                isNew={false}
                                cancelEdit={handleChangeEditStatus}
                            />
                            : <DisplayRow
                                key={e.position}
                                rowData={e}
                                deleteRow={handleDataDelete}
                                allowEdit={handleChangeEditStatus}
                                handleDragStart={(event) => handleDragStart(event, e)}
                                handleDragEnd={handleDragEnd}
                                handleDragOver={handleDragOver}
                                handleDrop={event => handleDrop(event, e)}

                            />
                        )}

                    </tbody>
                    <tfoot>
                        <AddEditRow
                            isNew={true}
                            handleDataChange={handleDataAdd}
                        />
                    </tfoot>
                </Table>
            </Accordian.Item>

            {/* chart options: y-axis */}
            <YAxisOptions
                eventKey={2}
                optionsData={optionsData}
                handleChange={handleChange}
            />

            {/* chart optoins: data color */}
            <Accordian.Item eventKey='1'>
                <Accordian.Header>{colColors.label}</Accordian.Header>
                <Accordian.Body>
                    <Row>
                        {colColors.options.map(option => (
                            <Col key={option.key} className='mb-3'>
                                <Form.Label htmlFor={option.key}>
                                    {option.title}
                                </Form.Label>
                                <Form.Control
                                    key={option.key}
                                    type='color'
                                    value={optionsData.colColors[option.key]}
                                    inline='true'
                                    onChange={e => handleColorChange(option.key, e.target.value)}
                                />
                            </Col>
                        ))}
                    </Row>
                </Accordian.Body>
            </Accordian.Item>

            {/* chart options: other */}
            {
                chartOptions.map((optGrp, i) => (

                    <Accordian.Item eventKey={3 + i} key={optGrp.key}>
                        <Accordian.Header>{optGrp.label}</Accordian.Header>
                        <Accordian.Body>
                            {optGrp.options.map(option => (
                                <Form.Check
                                    key={option.key}
                                    id={option.key}
                                    type={option.type}
                                    label={option.title}
                                    checked={
                                        option.type === 'switch'
                                            ? optionsData[option.key] ? true : false
                                            : optionsData[optGrp.key] === option.key ? true : false
                                    }
                                    inline='true'
                                    onChange={e => handleChange(option)}
                                />
                            ))}
                        </Accordian.Body>
                    </Accordian.Item>
                ))
            }

        </Accordian>
    )
}
// checked={optionsData[optGrp.key] === option.key ? true : false}

// drag & drop: 
// https://www.geeksforgeeks.org/drag-and-drop-sortable-list-using-reactjs/?ysclid=m3amfyw972818152610
// https://dev.to/florantara/creating-a-drag-and-drop-list-with-react-hooks-4c0i?ysclid=m3amfojrn8726890175
// https://medium.com/unlearninglabs/reactjs-implement-drag-and-drop-feature-without-using-external-libraries-ad8994429f1a