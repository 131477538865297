import React from 'react';

import { createRoot } from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'

import { router } from './app/router'

import 'bootstrap/dist/css/bootstrap.min.css'

/*
const styles = `
		* {
			margin: 0;
			padding: 0;
		}

		html, body {
			font-family: -apple-system, BlinkMacSystemFont, “Segoe UI”, “Roboto”, “Oxygen”, “Ubuntu”, “Canterell”, “Fira Sans”, “Droid Sans”, “Helvetica Neue”,
			background-color: #f3f5f7;
			background-repeat: no-repeat;
			background-position: right;
		}`

const styleSheet = document.createElement('style');
styleSheet.innerText = styles;
*/



const container = document.getElementById('root')
const root = createRoot(container)

root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
);