import Button from "react-bootstrap/Button"

import { saveToPNG } from "./utils"


// ============================================
//      Component
// ============================================
export function SaveButton({
    // for chart save
    filename = "BZ-Viz",       // name of saved file
    imgContainerID,
}) {

    return (

        <Button
            className="m-2"
            variant="primary"
            size="lg"
            onClick={() => saveToPNG(imgContainerID, filename)}
        >
            Save Chart
        </Button>
    )
}