import { useState } from "react";

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { ChartParent } from "./ChartParent";

export function PageLayout({
    OptionsComponent,
    OptionsInitData,
    ChartComponent,
}) {

    const [optionsData, setOptionsData] = useState(OptionsInitData)

    return (
        <Row className="p-0 m-0">

            <Col sm={0} md={4}>
                <OptionsComponent
                    optionsData={optionsData}
                    setOptionsData={setOptionsData}
                />
            </Col>

            <Col sm={12} md={8}>
                <ChartParent
                    Chart={ChartComponent}
                    chartOptions={optionsData}
                />
            </Col>

        </Row>
    )
}