/* common component for (1) adding new, and (2) in-line editing of, column

*/

import { useState } from "react";

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'

const initNewValues = {
    'position': 0,
    'label': '',
    'value': '',
}


export function AddEditRow({
    handleDataChange,           // from parent: handleDataEdit || handleDataAdd
    initValues = initNewValues,
    isNew = true,
    // if used as EditRow
    cancelEdit = () => { },
}) {
    // ===================
    // create state
    // ===================    
    const initValidity = isNew
        ? { 'label': false, 'value': false }
        : { 'label': true, 'value': true }

    const [colData, setColData] = useState({ ...initValues })
    const [inputValidity, setInputValidity] = useState(initValidity)
    const [canSave, setCanSave] = useState(false)


    // ===================
    // handle state change
    // ===================
    const showEditableStyle = e => e.target.style.background = 'yellow'

    const handleChange = e => {

        // manually track validity of each element b/c not w/in a form element
        setInputValidity({
            ...inputValidity,
            [e.target.name]: e.target.validity.valid,
        })

        setColData({
            ...colData,
            [e.target.name]: e.target.value
        })
    }

    const handleBlur = e => {

        // reset appearance to normal
        e.target.style.background = 'white'

        // disable || enable save button
        // - put value of each field into array, then check if every item passes        
        const isValid = Object.values(inputValidity).every(item => item)

        setCanSave(isValid)
    }

    const handleSave = () => {

        if (canSave) handleDataChange({ ...colData, 'value': parseFloat(colData.value), 'edit': false })

        // reset if used to add new column data
        if (canSave && isNew) {
            setColData(initNewValues)
            setInputValidity(initValidity)
            setCanSave(false)
        }
    }


    // ===================
    // component
    // ===================
    return (
        <tr className="align-middle">

            <td></td>

            {/* removed from text input: pattern='[a-zA-Z0-9 \[\]\(\)_*]+'  */}

            <td className="ms-0 ps-0">
                <Form.Control
                    type='text'
                    name='label'
                    autoFocus
                    value={colData.label}
                    required
                    maxLength='20'
                    onFocus={showEditableStyle}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </td>

            <td>
                <Form.Control
                    type='number'
                    name='value'
                    placeholder="0.01"
                    value={colData.value}
                    required
                    step="0.01"
                    onFocus={showEditableStyle}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </td>

            <td >
                {isNew
                    ? <Button
                        size='md'
                        variant={canSave ? 'primary' : 'outline-primary'}
                        type='button'
                        disabled={!canSave}
                        onClick={handleSave}
                    >
                        Add
                    </Button>
                    : <ButtonGroup size='sm' vertical>
                        <Button variant='outline-warning' onClick={() => cancelEdit(colData.position)}>
                            Cancel
                        </Button>
                        <Button variant={canSave ? 'outline-primary' : 'primary'} disabled={!canSave} onClick={handleSave}>
                            Save
                        </Button>
                    </ButtonGroup>
                }
            </td>

        </tr>
    )

}