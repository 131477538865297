import { PageLayout } from '../../components/PageLayout'

import { WaterfallChart } from './components/WaterfallChart'
// import { ChartOptionsCard } from './components/ChartOptionsCard'
import { ChartOptionsCardV2 } from './components/ChartOptionsCard.v2'
import { initInputData } from './components/data'

export function WaterfallPage() {


    return (
        <PageLayout
            OptionsComponent={ChartOptionsCardV2}
            OptionsInitData={initInputData}
            ChartComponent={WaterfallChart}
        />
    )
}