import { useEffect, useRef } from "react"

import Col from 'react-bootstrap/Col'

import * as d3Scale from 'd3-scale'
import * as d3Axis from 'd3-axis'
import * as d3Array from 'd3-array'
// import * as d3Format from 'd3-format'

import { calcMarginsAndDiemsions } from "../../../d3/utils"
// import { refLines } from "../../../d3/components"
import { addChartContainer } from "../../../d3/components"
import { chartBackgroundColor } from "../../../d3/data"

import { accessorFunctions } from "./data"

export function WaterfallChart({
    chartOptions,
}) {
    /* chartOptions is obj holding values and attributes for creating chart

        {
            data:               [ { 'position': int, 'label': str, 'value': int }, ],
            colColors:          { colorUp, colorDown, colorEnds },

            precision:          int
            format:             function to format data labels and y-axis values
            
            labelPosition:      str,

            showYAxis:          Bool
            yAxisDomain:        str,
            yAxisUnits:         str,
            ticksCustomValues:  [ ],

            // chartOrientation,    
        }
    */

    // prepare chart data
    const data = [...chartOptions.data].sort((a, b) => a.position - b.position)
    const dataSubs = data.length > 1
        ? data
            .slice(0, -1)   // remove last, presumable the end total
            .map(
                (e, i) => i === 0
                    ? accessorFunctions.yAxis(e)
                    : data
                        .slice(0, i + 1)
                        .reduce((a, b) => a + accessorFunctions.yAxis(b), 0)
            )
        : [accessorFunctions.yAxis(data[0])]    // only 1 datum

    // 1. define accessor functions
    // - see ./data.js

    // 2. calc/get responsive chart (bounds) dimensions & add margins
    const maxWidth = chartOptions.chartSize

    /*
    const chartWidth = chartElementWidth > maxWidth
        ? maxWidth
        : chartElementWidth
    */
    const chartWidth = maxWidth

    const dimensions = calcMarginsAndDiemsions({
        orient: chartOptions.showYAxis
            ? 'LEFTONLY'
            : 'SKINNYSIDES',
        chartElementWidth: chartWidth
    })

    const fontSize = 12 / (600 / maxWidth)

    // cannot rely on containerRef from ChartParent.jsx
    // as it refers to a <Row>, which overrides SVG width
    // so, add <Col> here w/ it's own containerRef
    const containerRef = useRef()

    useEffect(() => {

        // 3. draw canvas (add SVG)
        const [chartArea] = addChartContainer({ containerRef, dimensions })


        // refLines(chartContainer, chartArea, dimensions)


        // 4. declare scale
        const dataFunc = {
            'xScale': d3Scale
                .scaleBand()        // https://d3js.org/d3-scale/band
                .domain(chartOptions.data
                    .sort((a, b) => a.position - b.position)
                    .map(e => accessorFunctions.xAxis(e))
                )
                .range([0, dimensions.boundedWidth])
                .paddingInner(0.1),
            'yScale': d3Scale
                .scaleLinear()
                .domain(chartOptions.showYAxis && chartOptions.yAxisDomain === 'ticksCustom' && chartOptions.ticksCustomValues.length > 0
                    ? [
                        chartOptions.ticksCustomValues[0],
                        chartOptions.ticksCustomValues[chartOptions.ticksCustomValues.length - 1]
                    ]
                    : [
                        d3Array.min(dataSubs) > 0 ? 0 : d3Array.min(dataSubs),
                        d3Array.max(dataSubs),
                    ]
                )
                .range([dimensions.boundedHeight, 0])
        }

        // 5. draw data
        // - calc component positions
        const chartData = chartOptions.data
            .reduce(
                // calc coordinates for both current and next block
                (accum, curr) => {

                    // direction of prev determines start point of curr block
                    const origin = curr.position === chartOptions.data.length - 1   // edge case of end block
                        ? 0
                        : accum.direction === 0     // edge case of prev block as zero height
                            ? accum.top
                            : accum.direction > 0
                                ? accum.top
                                : accum.bottom

                    // calc position of curr blocks top & bottom for use by next blcok
                    const { top, bottom } = accessorFunctions.yAxis(curr) > 0
                        ? { "top": origin + accessorFunctions.yAxis(curr), "bottom": origin }
                        : { "top": origin, "bottom": origin + accessorFunctions.yAxis(curr) }

                    return {
                        data: [
                            ...accum.data,
                            {
                                ...curr,
                                'yStart': top,  // position curr block at 'top' b/c height is always a positive number (going down)
                                "fillColor": curr.position === 0 || curr.position === chartOptions.data.length - 1
                                    ? chartOptions.colColors.colorEnds
                                    : curr.value === 0
                                        ? chartBackgroundColor
                                        : curr.value > 0
                                            ? chartOptions.colColors.colorUp
                                            : chartOptions.colColors.colorDown
                            }
                        ],
                        direction: accessorFunctions.yAxis(curr),
                        top,
                        bottom,
                    }
                },
                {
                    data: [],
                    direction: 0,
                    top: 0,
                    bottom: 0,
                }
            )
            .data

        // see for bar chart & formatting: https://observablehq.com/@d3/bar-chart
        chartArea
            .append('g')
            .selectAll()
            .data(chartData).join('rect')
            .attr('x', d => dataFunc.xScale(d.label))
            .attr('y', d => dataFunc.yScale(d.yStart))
            .attr('width', dataFunc.xScale.bandwidth())
            .attr('height', d => dataFunc.yScale(0) - dataFunc.yScale(Math.abs(accessorFunctions.yAxis(d))))
            .attr('fill', d => d.fillColor)


        // 6. draw peripherals
        // - x-axis
        chartArea
            .append("g")
            .attr("transform", `translate(0, ${dataFunc.yScale(0)})`)
            .call(
                d3Axis
                    .axisBottom(dataFunc.xScale)
                    .tickSize(0)                // remove ticks
                    .tickPadding(fontSize)      // distance of text below axis
            )
            .call(g => chartOptions.showYAxis   // avoid doubling weight of horizontal axis line
                ? g.select('.domain').remove()
                : g.select('.domain').attr('stroke-width', 0.1)
            )
            .call(g => g.selectAll('text').attr('font-size', fontSize))
        // OPTIONAL: control 'font-size', 'fill' and 'font-family'
        // - for web safe fonts, see: https://developer.mozilla.org/en-US/docs/Learn/CSS/Styling_text/Fundamentals#web_safe_fonts
        //.selectAll('text')
        //.attr('font-family', 'fantasy')

        // - y-axis
        if (chartOptions.showYAxis) {

            const yRange = chartOptions.yAxisDomain === 'ticksCustom' && chartOptions.ticksCustomValues.length > 0
                ? chartOptions.ticksCustomValues
                : d3Array.ticks(...dataFunc.yScale.domain(), 5)                     // start, stop, count   : inclusive of stop

            chartArea
                .append('g')
                .call(d3Axis
                    .axisLeft(dataFunc.yScale)
                    .tickValues(yRange)
                    //.tickFormat(d => `¥ ${d}`)        // decimals???  see https://d3js.org/d3-axis#axis_tickFormat
                )
                .call(g => g.select('.domain').remove())        // remove vertical axis line
                //.call(g => g.selectAll('.tick line').clone()    // clone & extend ticks to create horizontal grid lines; see tick & grid line
                .call(g => g.selectAll('.tick line')            // turn ticks into horizontal grid lines
                    .attr('x2', dimensions.boundedWidth)
                    .attr('stroke-opacity', 0.1)
                )
                .call(g => g.select('.tick:last-of-type text').clone()  // add units to top of y-axis
                    .attr('x', 3)
                    .attr('text-anchor', 'start')
                    //.attr('font-weight', 'bold')
                    .text(chartOptions.yAxisUnits)
                )
                .call(g => g.selectAll('text').attr('font-size', fontSize))
        }

        // - data-label position
        const calcTextY = (yStart, value) => {

            const offset = fontSize / 2

            switch (chartOptions.labelPosition) {

                case 'labelAbove':
                    return [yStart, -offset]

                case 'labelTop':
                    return [yStart, 3 * offset]

                case 'labelMiddle':
                    return [yStart - Math.abs(value) / 2, offset]

                case 'labelBottom':
                    return [yStart - Math.abs(value), -offset]

                default:
                    return [yStart, -offset]
            }
        }

        if (chartOptions.labelPosition !== 'labelNone') {

            /*
            // superceded by chartOptions.format(),
            // which applies same formatting to all labels

            const formatLabel = v => Number.isInteger(v)
                ? v
                : d3Format.format(`.${v.toString().split('.')[1].length}f`)(v)
            */

            chartArea
                .append("g")
                .selectAll()
                .data(chartData).join('text')
                .text(d => chartOptions.format(d.value))
                //.text(d => formatLabel(d.value))
                .attr('text-anchor', 'middle')
                .attr('font-size', `${fontSize}px`)
                .attr('x', d => dataFunc.xScale(d.label) + dataFunc.xScale.bandwidth() / 2)
                .attr('y', d => dataFunc.yScale(calcTextY(d.yStart, d.value)[0]))
                .attr('dy', d => calcTextY(d.yStart, d.value)[1])
        }

        // - connecting line
        if (chartOptions.showConnectingLine) {

            const calcYPos = d => {

                return d.value > 0
                    ? dataFunc.yScale(d.yStart)
                    : dataFunc.yScale(d.yStart) + dataFunc.yScale(0) - dataFunc.yScale(Math.abs(accessorFunctions.yAxis(d)))
            }

            chartArea
                .append('g')
                .selectAll()
                .data(chartData.slice(0, -1)).join('line')
                .attr('x1', d => dataFunc.xScale(d.label) + dataFunc.xScale.bandwidth())
                .attr('y1', d => calcYPos(d))
                .attr('x2', d => dataFunc.xScale(d.label) + dataFunc.xScale.bandwidth() * (1 + dataFunc.xScale.padding()))
                .attr('y2', d => calcYPos(d))
                .attr('stroke', d => d.fillColor)
                .attr('stroke-width', 1)
        }

    })


    // component (chart)
    return (
        <Col className="m-0 mt-3 p-0" ref={containerRef} />
    )
}