import { createBrowserRouter } from 'react-router-dom'

import { App } from '../App'

import { WaterfallPage } from '../pages/waterfall/WaterfallPage'

export const router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        children: [
            {
                path: 'waterfall',
                element: <WaterfallPage />,
            },
        ]
    }
])