import * as d3Select from 'd3-selection'

import { chartBackgroundColor } from './data'


export function refLines(wrapper, bounds, dimensions) {
    /*  
        Add reference lines and margin values to chart
        - assumes bounds and margins
        - using rule of thirds w/in bounds
        - display margin and bound size values
    */

    // wrapper related
    const wrapperRefs = wrapper.append("g").attr("class", "wrapper-refs-lines-for-dev")

    // -- show edge
    wrapperRefs
        .append("rect")
        .attr("width", dimensions.width)
        .attr("height", dimensions.height)
        .attr("stroke", "blue")
        .attr("stroke-width", 5)
        .attr("fill", "none")

    // -- show title and footer
    wrapperRefs
        .append("line")
        .attr("x1", 0)
        .attr("x2", dimensions.width)
        .attr("y1", dimensions.titleMargin)
        .attr("y2", dimensions.titleMargin)
        .attr("stroke", "grey")
        .attr("stroke-width", 1)

    wrapperRefs
        .append("line")
        .attr("x1", 0)
        .attr("x2", dimensions.width)
        .attr("y1", dimensions.height - dimensions.footerMargin)
        .attr("y2", dimensions.height - dimensions.footerMargin)
        .attr("stroke", "grey")
        .attr("stroke-width", 1)

    // -- show margins
    wrapperRefs // bottom left
        .append("rect")
        .attr("y", dimensions.titleMargin + dimensions.marginTop + dimensions.boundedHeight)
        .attr("width", dimensions.marginLeft)
        .attr("height", dimensions.marginBottom)
        .attr("fill", "lightgrey");

    wrapperRefs // top right
        .append("rect")
        .attr("x", dimensions.marginLeft + dimensions.boundedWidth)
        .attr("y", dimensions.titleMargin)
        .attr("width", dimensions.marginRight)
        .attr("height", dimensions.marginTop)
        .attr("fill", "lightgrey");

    wrapperRefs // title (top left)
        .append("rect")
        .attr("width", dimensions.marginRight)
        .attr("height", dimensions.titleMargin)
        .attr("fill", "grey")

    wrapperRefs // footer (bottom right)
        .append("rect")
        .attr("x", dimensions.width - dimensions.marginRight)
        .attr("y", dimensions.height - dimensions.footerMargin)
        .attr("width", dimensions.marginRight)
        .attr("height", dimensions.footerMargin)
        .attr("fill", "grey")

    let marginvalues = [
        {
            hor: dimensions.marginLeft + dimensions.boundedWidth / 2,
            ver: dimensions.titleMargin + dimensions.marginTop / 3,
            txt: `boundedWidth: ${dimensions.boundedWidth}`,
            anchor: "middle"
        },
        {
            hor: dimensions.marginLeft + dimensions.boundedWidth / 2,
            ver: dimensions.titleMargin + dimensions.marginTop * 2 / 3,
            txt: `boundedHeight: ${dimensions.boundedHeight}`,
            anchor: "middle",
        },
        {
            hor: dimensions.marginLeft / 5,
            ver: dimensions.titleMargin + dimensions.marginTop + dimensions.boundedHeight + dimensions.marginBottom / 3,
            txt: `marginLeft: ${dimensions.marginLeft}`,
            anchor: "start"
        },
        {
            hor: dimensions.marginLeft / 5,
            ver: dimensions.titleMargin + dimensions.marginTop + dimensions.boundedHeight + dimensions.marginBottom * 2 / 3,
            txt: `marginBottom: ${dimensions.marginBottom}`,
            anchor: "start"
        },
        {
            hor: dimensions.marginLeft + dimensions.boundedWidth + dimensions.marginRight * 4 / 5,
            ver: dimensions.titleMargin + dimensions.marginTop / 3,
            txt: `marginRight: ${dimensions.marginRight}`,
            anchor: "end"
        },
        {
            hor: dimensions.marginLeft + dimensions.boundedWidth + dimensions.marginRight * 4 / 5,
            ver: dimensions.titleMargin + dimensions.marginTop * 2 / 3,
            txt: `marginTop: ${dimensions.marginTop}`,
            anchor: "end"
        }
    ];

    wrapperRefs
        .append("g")
        .selectAll("text")
        .data(marginvalues).join(enter => {

            const text = enter.append("text")

            text
                .attr("font-size", dimensions.fontText)
                .attr("text-anchor", d => d.anchor)
                .attr("x", d => d.hor)
                .attr("y", d => d.ver)
                .text(d => d.txt)
        });


    // show bounds related - bounds and internal grid
    const boundsref = bounds.append("g").attr("class", "bounds-ref-lines-for-dev")

    const gridcolor = "lightgrey"

    boundsref
        .append("rect")
        .attr("width", dimensions.boundedWidth)
        .attr("height", dimensions.boundedHeight)
        .attr("stroke", gridcolor)
        .attr("stroke-dasharray", "10, 3")
        .attr("fill", "none");

    boundsref
        .append("rect")
        .attr("x", dimensions.boundedWidth / 3)
        .attr("width", dimensions.boundedWidth / 3)
        .attr("height", dimensions.boundedHeight)
        .attr("stroke-dasharray", "10, 3")
        .attr("stroke", gridcolor)
        .attr("fill", "none");

    boundsref
        .append("rect")
        .attr("y", dimensions.boundedHeight / 3)
        .attr("width", dimensions.boundedWidth)
        .attr("height", dimensions.boundedHeight / 3)
        .attr("stroke-dasharray", "10, 3")
        .attr("stroke", gridcolor)
        .attr("fill", "none");
}


export function addChartContainer({ containerRef, dimensions }) {
    // containerRef: ref to chart element (<div>)
    // add structure: <SVG><g> </g></SVG>
    // and CR / source notice to bottom right    

    const chartElement = d3Select.select(containerRef.current)

    // clear previous content before render
    const everything = chartElement.selectAll("svg")
    everything.remove()

    // add chart container (wrapper / SVG)
    const chartContainer = chartElement
        .append("svg")
        .attr("class", "chart-container")
        .attr("width", dimensions.width)
        .attr("height", dimensions.height)
        .style("background-color", chartBackgroundColor)

    /*
    // add CR / source notice
    // const copyrightNotice = "VizBall.universalportfolio.ca"
    const copyrightNotice = "u/VizBall"
    chartContainer
        .append("text")
        .attr("class", "brand")
        .attr("x", dimensions.width - dimensions.marginRight / 5)
        .attr("y", dimensions.height - dimensions.footerMargin / 5)
        .text(copyrightNotice)
        .style("text-anchor", "end")
        .attr("font-size", dimensions.fontNotes)
    */

    // add chart area (bounds / g)
    const chartArea = chartContainer
        .append("g")
        .attr("class", "bounds")
        .attr("transform", `translate(${dimensions.marginLeft}, ${dimensions.marginTop + dimensions.titleMargin})`)

    return [chartArea, chartContainer];
}