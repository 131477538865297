// define input options
export const colColors = {
    key: 'colColors',
    label: 'Data Color',
    type: 'radio',
    options: [
        {
            groupKey: 'colColors',
            key: 'colorEnds',
            type: 'color',
            id: 'ends',
            title: 'Start/End',
        },
        {
            groupKey: 'colColors',
            key: 'colorUp',
            type: 'color',
            title: 'Increase',
        },
        {
            groupKey: 'colColors',
            key: 'colorDown',
            type: 'color',
            id: 'up',
            title: 'Decrease',
        },
    ]
}

// TBC
export const yAxis = {
    key: 'yAxis',
    label: 'Y-Axis',
    type: 'mixed',
    options: [
        {
            groupKey: 'yAxis',
            key: 'showYAxis',
            type: 'switch',
            title: 'Show Y-Axis',
        },
        {
            groupKey: 'yAxis',
            key: 'yAxisDomain',
            type: 'radio',
            title: 'Tick Values',
            options: [
                {
                    groupKey: 'yAxisDomain',
                    key: 'ticksDefault',
                    type: 'radio',
                    title: 'Auto tick values'
                },
                {
                    groupKey: 'yAxisDomain',
                    key: 'ticksCustom',
                    type: 'radio',
                    title: 'Custom tick values',
                },
            ],
            data: [],
        },
        {
            groupKey: 'yAxis',
            key: 'yAxisUnits',
            type: 'text',
            title: 'Units',
            value: ',0000 ¥'
        }
    ]
}


// ===================================

/*
const chartOrientation = {
    key: 'chartOrientation',
    label: 'Chart Orientation',
    type: 'radio',
    options: [
        {
            groupKey: 'chartOrientation',
            key: 'chartHorizontal',
            type: 'radio',
            title: 'Horizontal',
        },
        {
            groupKey: 'chartOrientation',
            key: 'chartVertical',
            type: 'radio',
            title: 'Vertical',
        }
    ]
}
*/

const labelPosition = {
    key: 'labelPosition',
    label: 'Data Label Position',
    type: 'radio',
    options: [
        {
            groupKey: 'labelPosition',
            key: 'labelAbove',
            type: 'radio',
            title: 'Above'
        },
        {
            groupKey: 'labelPosition',
            key: 'labelTop',
            type: 'radio',
            title: 'Top'
        },
        {
            groupKey: 'labelPosition',
            key: 'labelMiddle',
            type: 'radio',
            title: 'Middle'
        },
        {
            groupKey: 'labelPosition',
            key: 'labelBottom',
            type: 'radio',
            title: 'Bottom'
        },
        {
            groupKey: 'labelPosition',
            key: 'labelNone',
            type: 'radio',
            title: 'None'
        },
    ]
}

const chartSize = {
    key: 'chartSize',
    label: 'Chart Width',
    type: 'radio',
    options: [
        {
            groupKey: 'chartSize',
            key: '750',
            type: 'radio',
            title: '750px',
        },
        {
            groupKey: 'chartSize',
            key: '600',
            type: 'radio',
            title: '600px',
        },
        {
            groupKey: 'chartSize',
            key: '450',
            type: 'radio',
            title: '450px',
        }, {
            groupKey: 'chartSize',
            key: '300',
            type: 'radio',
            title: '300px',
        },
    ]
}

const chartSwitches = {
    key: 'allSwitches',
    label: 'Other Options',
    type: 'switch',
    options: [
        {
            groupKey: 'allSwitches',
            key: 'showConnectingLine',
            type: 'switch',
            title: 'Show Connecting Lines',

        },
    ]
}

// ==========================

export const chartOptions = [
    // chartOrientation,
    labelPosition,
    chartSize,
    chartSwitches,
]

export const initInputData = {
    'data': [   // initial chart data
        {
            'position': 0,
            'label': 'Initial',
            'value': 50,
            'edit': false,
        },
        {
            'position': 1,
            'label': 'Revenue',
            'value': 30,
            'edit': false,
        },
        {
            'position': 2,
            'label': 'Cost',
            'value': -15,
            'edit': false,
        },
        {
            'position': 3,
            'label': 'Final',
            'value': 65,
            'edit': false,
        },
    ],
    'colColors': {
        'colorUp': '#8ace00',
        'colorDown': '#ff0000',
        'colorEnds': '#808080',
    },
    'precision': 0,         // max number of decimal places of user inputted data
    'format': e => e,       // function to format data labels & y-axis labels based on 'precision' property
    //'chartOrientation': 'chartHorizontal',
    'labelPosition': 'labelAbove',
    'chartSize': '600',
    'showYAxis': true,
    'yAxisDomain': 'ticksDefault',
    'ticksCustomValues': yAxis.options[1].data,
    'yAxisUnits': yAxis.options[2].value
}

export const accessorFunctions = {
    'yAxis': d => d.value,
    'xAxis': d => d.label,
}