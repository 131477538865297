import { useState } from "react";

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

export function DisplayRow({
    rowData,
    deleteRow,
    allowEdit,
    // drag&drop
    handleDragStart,
    handleDragEnd,
    handleDragOver,
    handleDrop,
}) {

    const [show, setShow] = useState(false)
    const [rowStyle, setRowStyle] = useState('align-middle')


    const handleDelete = position => {

        setShow(false)
        deleteRow(position)
    }

    const dragStart = e => {

        setRowStyle('align-middle opacity-75')
        handleDragStart(e, rowData)
    }

    const dragEnd = () => {

        setRowStyle('align-middle')
        handleDragEnd()
    }


    return (
        <tr
            className={rowStyle}
            draggable

            onDragStart={dragStart}
            onDragEnd={dragEnd}
            onDragOver={handleDragOver}
            onDrop={event => handleDrop(event, rowData)}
        >

            <td
                className="me-0 pe-0"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-grip-vertical" viewBox="0 0 16 16">
                    <path d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0M7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0M7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                </svg>
            </td>

            <td className="text-center ms-0 ps-0" onClick={() => allowEdit(rowData.position)}>
                {rowData.label}
            </td>

            <td className="text-center" onClick={() => allowEdit(rowData.position)}>
                {rowData.value}
            </td>

            <td className="text-center" scope='row'>
                <Button
                    tabIndex={-1}
                    variant="outline-warning"
                    type='button'
                    onClick={() => setShow(true)}
                >
                    Delete
                </Button>
                <Modal show={show}>
                    <Modal.Header><Modal.Title>Confirm Delete</Modal.Title></Modal.Header>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShow(false)}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={() => handleDelete(rowData.position)} autoFocus>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </td>

        </tr>
    )

}