import { useEffect, useRef, useState } from "react";

export function useResizeMonitor() {
    // get current size of chart element (<div>) by adding event listener
    // - function returns event listener (ref) to add to target container
    // - and container's size properties

    const containerRef = useRef(null)

    // assume chart is rectangular
    // mergeDimensions() will change to square if specified by user
    //const goldenRatio = 1618    // 1.618 * 1000

    // hold wrapper dimensions; updating in useEffect will trigger re-render
    // useEffect itself only evaluated once (see empty dependency array below)
    const [chartElementWidth, setChartElementWidth] = useState(0)
    //const [wrapperHeight, setWrapperHeight] = useState(0)

    useEffect(() => {

        const getContainerSize = () => {

            // get current dimensions of element
            const newWidth = containerRef.current.clientWidth
            //const newHeight = containerRef.current.clientHeight

            // update state
            setChartElementWidth(newWidth)
            //setWrapperHeight(newHeight)
        }

        // detect size on render
        getContainerSize()

        // listen for resize events and detect new dimnensions
        window.addEventListener("resize", getContainerSize);

        // cleanup
        return () => window.removeEventListener("resize", getContainerSize)

    }, [])

    //return [wrapperWidth, wrapperHeight, containerRef];
    return [chartElementWidth, containerRef];
}


export function calcMarginsAndDiemsions({
    orient = 'BOTTOM-LEFT',
    chartElementWidth
}) {
    /* NOTES:
    //      Chart element is the <div> holding the chart container (<SVG>).
    //      W/in the chart container is the chart itself (chart area).
    //      Using the provided width of the chart element,
    //      calculate the dimensions of the chart container
    //      and the margins w/in (which implicitly defines the size of the chart).

    // Take width of chart element (chartElementWidth) and calculate values for:
    //  - chart title height
    //  - chart container interior margins (marginTop, marginBottom, marginLeft and marginRight)
    //  - chart dimensions (boundedWidth and boundedHeight)
    //  - chart footer height
    //      - 7 of 8 above values are calculated as % of given width (chartElementWidth)
    //      - height of chart (boundedHeight) is calculated based on chart width (boundedWidth)
    //
    //  height of chart container is calculated / defined by this function
    //
    //  font sizes also calculated per these ratios
    //      ref - https://urbaninstitute.github.io/graphics-styleguide/
    //
    // width = marginLeft + boundedWidth + marginRight
    //      where marginLeft and marginRight are calculated as portion of width
    //      and boundedWidth (chart height) is the remainder
    //
    // height = titleMargin + marginTop + boundedHeight + marginBottom + footerMargin
    //      where boundedHeight is calculated based on boundedWidth (golden ratio or same size)
    //      and remaining values are calculated as portion of width
    */

    // constants
    //const chartTitleMargin = 0.1
    //const footerMargin = 0.05
    const chartTitleMargin = 0
    const footerMargin = 0

    const adjustment = 0.02     // for top and bottom b/c no title and footer

    // - margin percent for chart margins
    const majMargin = 0.08
    const minMargin = 0.04

    const goldenRatio = 1.618   // used to calculate chart height (boundedHeight) if chart is rectangular

    const sqMargin = 0.125      // used to calculate chart height (boundedHeight) if chart is square

    // orientation and shape of chart
    const sides = orient.toUpperCase().split("-")

    // calculate
    const margins = {}

    margins.titleMargin = Math.round(chartElementWidth * chartTitleMargin)
    margins.footerMargin = Math.round(chartElementWidth * footerMargin)

    // - chart margins
    sides.forEach(side => {

        switch (side) {

            case 'BOTTOM':

                margins.marginTop = Math.round(chartElementWidth * minMargin)
                margins.marginBottom = Math.round(chartElementWidth * majMargin)

                break;

            case 'TOP':

                margins.marginTop = Math.round(chartElementWidth * majMargin)
                margins.marginBottom = Math.round(chartElementWidth * minMargin)

                break;

            case 'LEFT':

                margins.marginLeft = Math.round(chartElementWidth * majMargin)
                margins.marginRight = Math.round(chartElementWidth * minMargin)

                break;

            case 'LEFTONLY':

                margins.marginLeft = Math.round(chartElementWidth * majMargin)
                margins.marginRight = Math.round(chartElementWidth * minMargin)

                margins.marginTop = Math.round(chartElementWidth * (minMargin + adjustment))
                margins.marginBottom = Math.round(chartElementWidth * (minMargin + adjustment))

                break;

            case 'RIGHT':

                margins.marginLeft = Math.round(chartElementWidth * minMargin)
                margins.marginRight = Math.round(chartElementWidth * majMargin)

                break;

            case 'SQUARE':

                margins.marginTop = Math.round(chartElementWidth * sqMargin);
                margins.marginBottom = Math.round(chartElementWidth * sqMargin);

                margins.marginRight = Math.round(chartElementWidth * sqMargin);
                margins.marginLeft = Math.round(chartElementWidth * sqMargin);

                break;

            case 'FATSIDES':
                // for exposed stat, where square results in too tall a chartContainer
                // want rectangle w/ even margin
                margins.marginTop = Math.round(chartElementWidth * minMargin)
                margins.marginBottom = Math.round(chartElementWidth * minMargin)

                margins.marginLeft = Math.round(chartElementWidth * majMargin)
                margins.marginRight = Math.round(chartElementWidth * majMargin)

                break

            case 'SKINNYSIDES':
                // for treemap
                margins.marginTop = Math.round(chartElementWidth * (minMargin + adjustment))
                margins.marginBottom = Math.round(chartElementWidth * (minMargin + adjustment))

                margins.marginLeft = Math.round(chartElementWidth * minMargin)
                margins.marginRight = Math.round(chartElementWidth * minMargin)

                break

            default:

                break;
        }
    })

    // - chart dimensions
    const boundedWidth = Math.round(chartElementWidth - margins.marginLeft - margins.marginRight)
    const boundedHeight = orient.toUpperCase() === 'SQUARE'
        ? boundedWidth
        : Math.round(boundedWidth / goldenRatio)

    // - combine
    const dimensions = {
        ...margins,
        boundedWidth: boundedWidth,
        boundedHeight: boundedHeight,
        // 'width' & 'height' used to define chart container (SVG)
        width: Math.round(chartElementWidth),
        height: Math.round(margins.titleMargin + margins.marginTop + boundedHeight + margins.marginBottom + margins.footerMargin),
    }

    // add font sizes
    // ref - https://urbaninstitute.github.io/graphics-styleguide/
    dimensions.fontTitle = Math.min(0.06 * dimensions.boundedWidth, 20);
    dimensions.fontSubTitle = Math.min(0.04 * dimensions.boundedWidth, 14);
    dimensions.fontText = Math.min(0.03 * dimensions.boundedWidth, 12);
    dimensions.fontNotes = Math.min(0.02 * dimensions.boundedWidth, 8);
    dimensions.fontBrand = Math.min(0.02 * dimensions.boundedWidth, 8);

    return dimensions;
}