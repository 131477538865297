function drawSVGtoCanvas(svg) {
    // https://stackoverflow.com/questions/63713762/how-to-convert-multiple-svgs-to-one-png-image

    const { width, height } = svg.getBoundingClientRect();

    const serializer = new XMLSerializer();

    const copy = svg.cloneNode(true);

    const data = serializer.serializeToString(copy);

    const image = new Image();

    const blob = new Blob([data], {
        type: 'image/svg+xml;charset=utf-8'
    });

    const url = URL.createObjectURL(blob);

    return new Promise(resolve => {

        const canvas = document.createElement('canvas');

        const ctx = canvas.getContext('2d');

        canvas.width = width;
        canvas.height = height;

        image.addEventListener('load', () => {

            ctx.drawImage(image, 0, 0, width, height);

            URL.revokeObjectURL(url);

            resolve(canvas);

        }, { once: true });

        image.src = url;
    });
}
export async function saveToPNG(containerID, filename) {
    // see ~/vizball/react_client/src/d3Chart/saveToPNG.saveMultipleSVGv2()

    // SVG may have child SVG w/in it, and image of child saved twice (once w/ parent and then on own)
    // avoid by query for all <div> w/in container, then w/in each <div> search for first (parent) SVG
    // assumed structure:
    /*
        <div imgContainer>
            <div chartParent>
                <SVG parent>
                    <SVG child>
            <div chartParent>
                <SVG parent>
                    <SVG child>                    
    */

    // console.log("saveToPNG")

    // get divs (chart containers) from imgContainer
    const divs = document.getElementById(containerID).querySelectorAll('div')

    // get parent SVG from each div
    const svgs = Array.from(divs).map(div => div.querySelector('svg'))

    // get images
    const renders = await Promise.all(svgs.map(svg => drawSVGtoCanvas(svg)))

    // create canvas onto which all charts will be drawn as single image
    const canvas = document.createElement('canvas')

    const ctx = canvas.getContext('2d')

    // render images onto canvas
    canvas.width = renders[0].width
    canvas.height = renders[0].height

    ctx.drawImage(renders[0], 0, 0, renders[0].width, renders[0].height)

    const dataURL = canvas.toDataURL(`image/png`, 1.0);

    // make available to download
    const link = document.createElement("a");

    link.setAttribute("href", dataURL);
    link.setAttribute("download", `${filename}.png`);

    document.querySelector('body').append(link);

    link.click();

    // clean up
    link.remove();
    canvas.remove();
}