import { LinkContainer } from 'react-router-bootstrap'

import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

export function NavBar() {
    return (
        <Navbar
            expand="lg"
            className='flex-md-column border rounded-3'
        >

            <LinkContainer to="/" >
                <Navbar.Brand className='mt-0 m-3 fs-1'> BZ Viz </Navbar.Brand>
            </LinkContainer>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">

                <Nav className="flex-md-column me-auto">

                    <LinkContainer to="waterfall/" >
                        <Nav.Link>Waterfall Chart</Nav.Link>
                    </LinkContainer>

                </Nav>
            </Navbar.Collapse>

        </Navbar>
    );
}