import { Outlet } from 'react-router-dom'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { NavBar } from './app/nav/NavBar'

export function App() {

  return (
    <Container fluid className='p-0 m-0'>
      <Row className='p-0 m-0'>

        <Col sm={0} lg={2} xl={2}>
          <NavBar />
        </Col>

        <Col sm={12} lg={10} xl={9}>
          <Outlet />
        </Col>

        <Col xl={1} />
      </Row>

    </Container>
  );
}